<template>
  <div>
    <div v-if="shopno" @click="getShopInfo(shopno, brandno)" class="more">
      {{ shoptitle }}
    </div>
    <b-modal
      id="modal-info-shop"
      ref="modal-info-shop"
      size="lg"
      centered
      scrollable
      footer-class="btn-bottom-wrapper"
    >
      <template #modal-header="{ close }">
        <h5 class="modal-title">매장정보</h5>
        <b-button variant="close" @click="close()"></b-button>
      </template>
      <template>
        <h6 class="sub-title">기본정보</h6>
        <div class="row g-0 table-type header-col">
          <div class="col-4 col-md-2">
            <span>매장</span>
          </div>
          <div class="col-8 col-md-10 shop-photo">
            <div class="card border-0">
              <div class="row g-0">
                <div class="col-md-3 d-flex align-items-center">
                  <img
                    :src="shopbasicinfo.filepath | get_img"
                    class="img-fluid"
                    alt="매장전경"
                  />
                </div>
                <div class="col-md-9 d-flex align-items-center">
                  <div class="card-body p-0 pt-3 pb-1 ps-md-3 py-md-2">
                    <h6
                      class="card-title mb-1 brand-name lh-base"
                      v-if="admin_login"
                    >
                      {{ shopbasicinfo.brandname }}
                    </h6>
                    <p class="shop-name lh-sm">
                      {{ shopbasicinfo && shopbasicinfo.shopname }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-4 col-md-2">
            <span>주소</span>
          </div>
          <div class="col-8 col-md-4 d-flex align-items-center">
            {{ shopbasicinfo && shopbasicinfo.shopaddress }}
          </div>

          <div class="col-4 col-md-2">
            <span>메모</span>
          </div>
          <div class="col-8 col-md-4">
            <textarea class="h-100" v-model="sendData.shopmemo"></textarea>
          </div>

          <div class="col-4 col-md-2">
            <span>대표전화</span>
          </div>
          <div class="col-8 col-md-4">
            {{ shopbasicinfo && shopbasicinfo.shopphone }}
          </div>

          <div class="col-4 col-md-2">
            <span>팩스</span>
          </div>
          <div class="col-8 col-md-4">
            {{ shopbasicinfo && shopbasicinfo.shopfax }}
          </div>
          <div class="col-4 col-md-2">
            <span>이메일</span>
          </div>
          <div class="col-8 col-md-4">
            {{ shopbasicinfo && shopbasicinfo.shopemail }}
          </div>

          <div class="col-4 col-md-2">
            <span>회원수</span>
          </div>
          <div class="col-8 col-md-4 d-flex align-items-center text-cyan">
            {{ shopbasicinfo && shopbasicinfo.shopmembercnt }}
          </div>

          <div class="col-4 col-md-2">
            <span>피팅횟수</span>
          </div>
          <div class="col-8 col-md-4 text-purple">
            {{ shopbasicinfo && shopbasicinfo.shopfittingcnt }}
          </div>

          <div class="col-4 col-md-2">
            <span>누적매출</span>
          </div>
          <div class="col-8 col-md-4 text-price">
            {{ shopbasicinfo && shopbasicinfo.shopsalesum }}
          </div>

          <div class="col-4 col-md-2 border-bottom-md-0">
            <span>구매횟수</span>
          </div>
          <div class="col-8 col-md-4 border-bottom-md-0 text-orange">
            {{ shopbasicinfo && shopbasicinfo.shopsalecnt }}
          </div>

          <div class="col-4 col-md-2 border-bottom-0">
            <span>A/S건수</span>
          </div>
          <div class="col-8 col-md-4 text-green">
            {{ shopbasicinfo && shopbasicinfo.shopascnt }}
          </div>

          <!--           <div class="col-4 col-md-2">
            <span>룸개수</span>
          </div>
          <div class="col-8 col-md-4">
            {{ shopbasicinfo && shopbasicinfo.roomcnt }}
          </div> -->
        </div>

        <h6 class="sub-title">
          룸 정보
          <small class="fw-normal"
            >(룸
            <b class="text-primary">{{
              shopbasicinfo && shopbasicinfo.roomcnt
            }}</b
            >개)</small
          >
        </h6>

        <table class="table board" v-if="data_yn">
          <thead>
            <tr>
              <th class="col-auto d-lg-table-cell w-1 text-center">룸</th>
              <th class="col-auto d-lg-table-cell w-1 text-center">클럽구분</th>
              <th class="col-auto d-lg-table-cell text-center">상세</th>
            </tr>
          </thead>
          <tbody v-if="shopbasicinfo.roomcnt">
            <template v-for="(item1, index1) in roomgoodslist">
              <tr :key="index1 + 'r'">
                <td
                  class="col-auto d-lg-table-cell text-center text-nowrap border-end"
                  v-if="
                    index1 == 0 ||
                    item1.roomno !== roomgoodslist[index1 - 1].roomno
                  "
                  :rowspan="item1.roomgoodsroomcnt"
                >
                  {{ item1.roomno }}번방
                </td>
                <td
                  class="col-auto d-lg-table-cell border-end"
                  v-if="
                    index1 == 0 ||
                    item1.roomgoodstype !==
                      roomgoodslist[index1 - 1].roomgoodstype ||
                    (item1.roomgoodstype ==
                      roomgoodslist[index1 - 1].roomgoodstype &&
                      item1.roomno !== roomgoodslist[index1 - 1].roomno)
                  "
                  :rowspan="item1.roomgoodstypecnt"
                >
                  <span class="goods-kind">{{
                    club_type(item1.roomgoodstype)
                  }}</span>
                </td>
                <td class="col-auto d-lg-table-cell p-0">
                  <template
                    v-for="(item2, index2) in item1.roomgoodsinfodetail"
                  >
                    <table v-if="index2 == 0" :key="index2 + 'l'">
                      <tr>
                        <td class="col-2 d-none d-lg-table-cell">
                          <img
                            :src="goods_imgview(item1.roomgoodsimage)"
                            class="img-thumbnail"
                          />
                        </td>
                        <td class="col-auto d-lg-table-cell text-start">
                          {{ item1.roomgoodsname }} {{ item2.clubtypename }} ({{
                            item2.clubloft
                          }}&deg;) {{ item1.roomgoodsbalance }}
                        </td>
                        <td
                          class="col-auto d-lg-table-cell w-1 p-0"
                          v-if="item1.roomgoodscnt > 1"
                        >
                          <b-button
                            size="xs"
                            variant="bundle bg-primary"
                            aria-label="더보기"
                            :class="{ show: view_hide_shop_club === index1 }"
                            @click="toggle_shop_tr(index1)"
                          ></b-button>
                        </td>
                      </tr>
                    </table>

                    <table
                      v-else
                      :key="index2 + 'l'"
                      class="bg-light bg-opacity-50"
                    >
                      <tr
                        class="border-top"
                        :class="{ show: view_hide_shop_club === index1 }"
                        v-if="view_hide_shop_club === index1"
                      >
                        <td class="col-2 d-none d-lg-table-cell">
                          <img
                            :src="goods_imgview(item1.roomgoodsimage)"
                            class="img-thumbnail"
                          />
                        </td>
                        <td class="col-auto d-lg-table-cell text-start">
                          {{ item1.roomgoodsname }} {{ item2.clubtypename }} ({{
                            item2.clubloft
                          }}&deg;)
                        </td>
                        <td class="col-auto d-lg-table-cell w-1 p-0">
                          <b-button
                            size="xs"
                            variant="bundle bg-primary invisible"
                            aria-label="더보기"
                            :class="{ show: view_hide_shop_club === index1 }"
                            @click="toggle_shop_tr(index1)"
                          ></b-button>
                        </td>
                      </tr>
                    </table>
                  </template>
                </td>
              </tr>
            </template>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="12">데이터가 없습니다.</td>
            </tr>
          </tbody>
        </table>

        <!-- <div v-for="(item1, index1) in roomgoodslist" :key="index1">
              {{ item1.roomno }} : {{ item1.roomgoodstype }} : {{ item1.roomgoodsno }}
              <div v-for="(item2, index2) in item1.roomgoodsinfodetail" :key="index2">
                {{ item1.roomgoodsimage }}  {{ item1.roomgoodsname }} {{ item2.clubtypename }} {{ item2.clubloft }}
              </div>
            </div> -->
      </template>
      <template #modal-footer="{}">
        <b-button
          size="md"
          variant="outline-secondary"
          aria-label="닫기"
          @click="$bvModal.hide('modal-info-shop')"
        >
          닫기
        </b-button>
        <b-button
          size="md"
          variant="secondary"
          aria-label="확인"
          @click="setShopWrite()"
        >
          확인
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import ax from "@/api/shop";
import "@/filter/common";
import { myMixin } from "@/mixin/myMixin";
export default {
  name: "ShopInfo",
  props: {
    shoptitle: String,
    shopno: String,
    brandno: String,
  },
  data() {
    return {
      // playerno: "",
      shopbasicinfo: [],
      sendData: {
        shopno: "",
        brandno: "",
        shopmemo: "",
      },
      data_yn: true,
      // goods_yn: false,
      view_hide_shop_club: null,

      // roomlistcnt: 0,
      // roomlist:[],
      // roomgoodslistcnt: 0,
      roomgoodslist: [],
      roomgoodsinfodetail: [],
      admin_login: sessionStorage.getItem("isAdmin") == "1" ? true : false,
    };
  },
  methods: {
    getShopInfo(shopno, brandno) {
      // console.log(shopno, brandno);
      ax.get_shopinfo(shopno, brandno, (flag, data) => {
        console.log(flag, data);
        if (flag) {
          // this.playerno = playerno;
          this.shopbasicinfo = data.shopbasicinfo;
          this.sendData.brandno = brandno;
          this.sendData.shopno = shopno;
          this.sendData.shopmemo = data.shopbasicinfo.shopmemo;
          this.$refs["modal-info-shop"].modalOuterStyle.zIndex = 9999;
          this.$refs["modal-info-shop"].show();
          this.roomgoodslist = data.roomgoodslist;

          if (data.roomgoodslist) {
            for (let i = 0; i < data.roomgoodslist.length; i++) {
              for (
                let j = 0;
                j < data.roomgoodslist[i].roomgoodsinfodetail.length;
                j++
              ) {
                this.roomgoodsinfodetail[j] =
                  data.roomgoodslist[i].roomgoodsinfodetail[j];
                // console.log(j, this.roomgoodsinfodetail[j]);
              }
            }
          }

          // this.getShopRoomlist(shopno);
        } else {
          alert(data);
        }
      });
    },
    setShopWrite() {
      if (!this.sendData.shopmemo) {
        alert("내용을 입력하세요");
        return false;
      }

      ax.set_shopwrite(this.sendData, (flag, response) => {
        if (flag) {
          alert(response.message);
          $bvModal.hide("modal-info-shop");
          // this.sendData.shopmemo = "";
        } else {
          /*this.$notify({
              group: 'alert',
              type: 'info',
              title: '정보',
              text: '발송에 실패하였습니다.'
            })*/
          alert(response.message);
        }
      });
    },
    // getShopRoomlist(shopno) {
    //   ax.get_shoproomlist(shopno, (flag, data) => {
    //     console.log(flag, data);
    //     if (flag) {
    //       this.roomlistcnt = data.roomlist.length;
    //       if (data.roomlist.length <= 0) {
    //         this.data_yn = false;
    //         return false;
    //       } else {
    //         this.data_yn = true;
    //       }
    //       this.roomlist = data.roomlist;
    //       // this.roomlist.roomno = data.roomlist.roomno;
    //       // this.roomlist.roomname = data.roomlist.roomname;
    //     } else {
    //       alert(data);
    //     }
    //   });
    // },

    toggle_shop_tr(index) {
      if (this.view_hide_shop_club === index) this.view_hide_shop_club = null;
      else this.view_hide_shop_club = index;
    },
    goods_imgview(imagename) {
      if (imagename !== null && imagename !== "") {
        //   return this.adlist[index].path + "/" + this.adlist[index].adimagepath;
        return process.env.VUE_APP_S3_BUCKET_URL + "/" + imagename;
      } else {
        return "/img/no-img.jpg";
      }
    },
  },
  mixins: [myMixin],
};
</script>
