<template>
  <div class="d-inline-block">
    <span v-if="memberno" @click="getMemberInfo(memberno)" class="more">
      {{ nickname }}
    </span>
    <!-- <div
      v-if="memberno"
      @click="getMemberInfo(memberno)"
      class="pointer more"
    >
      {{ memberno }}
    </div> -->
    <b-modal
      id="modal-info-member"
      ref="modal-info-member"
      size="lg"
      centered
      scrollable
      no-enforce-focus
      footer-class="btn-bottom-wrapper"
    >
      <template #modal-header="{ close }">
        <h5 class="modal-title">회원정보</h5>
        <b-button variant="close" @click="close()"></b-button>
      </template>
      <template>
        <h6 class="sub-title">기본 정보</h6>
        <div class="row g-0 table-type header-col">
          <div class="col-md-2">
            <span>닉네임</span>
          </div>
          <div class="col-md-4">
            {{ playerbasicinfo && playerbasicinfo.membername }}
          </div>
          <div class="col-md-2">
            <span>성별</span>
          </div>
          <div class="col-md-4">
            {{ playerbasicinfo && playerbasicinfo.membergender | gender }}
          </div>

          <div class="col-md-2">
            <span>가입일</span>
          </div>
          <div class="col-md-4 text-date">
            {{ playerbasicinfo && playerbasicinfo.regdate }}
          </div>
          <div class="col-md-2">
            <span>생년월일</span>
          </div>
          <div class="col-md-4 text-date">
            {{ playerbasicinfo && playerbasicinfo.birthdate }}
          </div>

          <div class="col-md-2">
            <span>단골매장</span>
          </div>
          <div class="col-md-4 lh-sm shop-name">
            {{ playerbasicinfo && playerbasicinfo.shopname }}
          </div>
          <div class="col-md-2">
            <span>이메일</span>
          </div>
          <div class="col-md-4 lh-sm">
            {{ playerbasicinfo && playerbasicinfo.email }}
          </div>

          <div class="col-md-2">
            <span>피팅횟수</span>
          </div>
          <div class="col-md-4">
            <h5 class="fw-semibold text-purple">
              {{ playerbasicinfo && playerbasicinfo.fittingcnt | comma("-")
              }}<small class="text-purple">건</small>
            </h5>
          </div>
          <div class="col-md-2">
            <span>구매횟수</span>
          </div>
          <div class="col-md-4">
            <h5 class="fw-semibold text-orange">
              {{ playerbasicinfo && playerbasicinfo.salecnt | comma("-")
              }}<small class="text-orange">건</small>
            </h5>
          </div>

          <div class="col-md-2">메모</div>
          <div class="col-md-10">
            <textarea class="" v-model="sendData.membermemo"></textarea>
          </div>
        </div>

        <h6 class="sub-title">문진 정보</h6>
        <div class="row g-0 table-type header-col">
          <div class="col-md-3">
            <span>신장</span>
          </div>
          <div class="col-md-3">
            {{ playerbasicinfo && playerbasicinfo.height }}cm
          </div>
          <div class="col-md-3">
            <span>바닥에서 손목높이</span>
          </div>
          <div class="col-md-3">
            {{ playerbasicinfo && playerbasicinfo.handheight }}cm
          </div>

          <div class="col-md-3">
            <span>몸무게</span>
          </div>
          <div class="col-md-3">
            {{ playerbasicinfo && playerbasicinfo.weight }}kg
          </div>
          <div class="col-md-3">
            <span>핸디캡</span>
          </div>
          <div class="col-md-3">
            {{ playerbasicinfo.handicap | get_handicap }}
          </div>

          <div class="col-md-3">
            <span>평균드라이버거리</span>
          </div>
          <div class="col-md-3">
            {{ playerbasicinfo.driverdistance | get_driverdistance }}m
          </div>
          <div class="col-md-3">
            <span>클럽의 중요성</span>
          </div>
          <div class="col-md-3">
            {{ playerbasicinfo.interestclub | get_interestclub }}
          </div>

          <div class="col-md-3">
            <span>선호 샤프트</span>
          </div>
          <div class="col-md-3">
            {{ playerbasicinfo.interestshaft | get_balance }}
          </div>
          <div class="col-md-3">
            <span>손 사이즈</span>
          </div>
          <div class="col-md-3">
            {{ playerbasicinfo && playerbasicinfo.glovesize }}cm
          </div>

          <div class="col-md-3"><span>중지길이</span>cm</div>
          <div class="col-md-3">
            {{ playerbasicinfo && playerbasicinfo.fingersize }}
          </div>
          <div class="col-md-3">
            <span>가슴 둘레</span>
          </div>
          <div class="col-md-3">
            {{ playerbasicinfo && playerbasicinfo.shirtssize }}cm
          </div>

          <div class="col-md-3">
            <span>허리 둘레</span>
          </div>
          <div class="col-md-3">
            {{ playerbasicinfo && playerbasicinfo.pantssize }}Inch
          </div>
          <div class="col-md-3">
            <span>신발 사이즈</span>
          </div>
          <div class="col-md-3">
            {{ playerbasicinfo && playerbasicinfo.shoessize }}mm
          </div>

          <div class="col-md-3">
            <span>보유 장비</span>
          </div>
          <div class="col-md-9 py-0">
            <div class="row p-2 ps-0 border-bottom">
              <div class="col-md-3 goods-kind">드라이버</div>
              <div class="col-md-9 small">
                <span class="brand-name">{{
                  playerbasicinfo && playerbasicinfo.drivername
                }}</span>
                <span class="text-date">
                  / {{ playerbasicinfo && playerbasicinfo.driverdate }}년</span
                >
              </div>
            </div>

            <div class="row p-2 ps-0 border-bottom">
              <div class="col-md-3 goods-kind">우드</div>
              <div class="col-md-9 small">
                <span class="brand-name">{{
                  playerbasicinfo && playerbasicinfo.woodname
                }}</span>
                <span class="text-date">
                  / {{ playerbasicinfo && playerbasicinfo.wooddate }}년</span
                >
              </div>
            </div>

            <div class="row p-2 ps-0 border-bottom">
              <div class="col-md-3 goods-kind">아이언</div>
              <div class="col-md-9 small">
                <span class="brand-name">{{
                  playerbasicinfo && playerbasicinfo.ironname
                }}</span>
                <span class="text-date">
                  / {{ playerbasicinfo && playerbasicinfo.irondate }}년</span
                >
              </div>
            </div>

            <div class="row p-2 ps-0">
              <div class="col-md-3 goods-kind">퍼터</div>
              <div class="col-md-9 small">
                <span class="brand-name">{{
                  playerbasicinfo && playerbasicinfo.puttername
                }}</span>
                <span class="text-date">
                  / {{ playerbasicinfo && playerbasicinfo.putterdate }}년</span
                >
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #modal-footer="{}">
        <b-button
          size="md"
          variant="outline-secondary"
          aria-label="닫기"
          @click="$bvModal.hide('modal-info-member')"
        >
          닫기
        </b-button>
        <b-button
          size="md"
          variant="secondary"
          aria-label="확인"
          @click="setMemberWrite()"
        >
          확인
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import ax from "@/api/member";
import "@/filter/common";
export default {
  name: "MemberInfo",
  props: {
    nickname: String,
    memberno: String,
  },
  data() {
    return {
      // playerno: "",
      playerbasicinfo: [],
      sendData: {
        memberno: "",
        membermemo: "",
      },
    };
  },
  methods: {
    getMemberInfo(memberno) {
      ax.get_playerinfo(memberno, (flag, data) => {
        console.log(flag, data);
        if (flag) {
          // this.playerno = playerno;
          this.playerbasicinfo = data.playerbasicinfo;
          this.sendData.memberno = memberno;
          this.sendData.membermemo = data.playerbasicinfo.membermemo;
          this.$refs["modal-info-member"].modalOuterStyle.zIndex = 9999;
          this.$refs["modal-info-member"].show();
        } else {
          alert(data);
        }
      });
    },
    setMemberWrite() {
      if (!this.sendData.membermemo) {
        alert("내용을 입력하세요");
        return false;
      }

      ax.set_memberwrite(this.sendData, (flag, response) => {
        if (flag) {
          alert(response.message);
          this.$bvModal.hide("modal-info-member");
          // this.sendData.membermemo = "";
        } else {
          /*this.$notify({
              group: 'alert',
              type: 'info',
              title: '정보',
              text: '발송에 실패하였습니다.'
            })*/
          alert(response.message);
        }
      });
    },
  },
};
</script>
