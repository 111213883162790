const routes = {
  path: "/goods",
  name: "Goods",
  component: () =>
    import(/* webpackChunkName: "tournament" */ "@/views/goods/Main"),
  children: [
    {
      path: "goodslist/:goodstype?",
      alias: "",
      name: "GoodsList",
      component: () =>
        import(/* webpackChunkName: "tournament" */ "@/views/goods/GoodsList"),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "종류별",
        menu_num: 1,
        sub_menu_num: 11,
        step: ["제품", "종류별"],
      },
    },
    {
      path: "goodswrite/:goodsno?",
      name: "GoodsWrite",
      component: () =>
        import(/* webpackChunkName: "tournament" */ "@/views/goods/GoodsWrite"),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "클럽 등록",
        menu_num: 1,
        sub_menu_num: 12,
        step: ["제품", "클럽 등록"],
      },
    },
    {
      path: "shaftlist",
      alias: "",
      name: "ShaftList",
      component: () =>
        import(/* webpackChunkName: "tournament" */ "@/views/goods/ShaftList"),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "종류별",
        menu_num: 1,
        sub_menu_num: 11,
        step: ["제품", "종류별"],
      },
    },
    {
      path: "shaftwrite/:shaftno?",
      name: "ShaftWrite",
      component: () =>
        import(/* webpackChunkName: "tournament" */ "@/views/goods/ShaftWrite"),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "샤프트 등록",
        menu_num: 1,
        sub_menu_num: 13,
        step: ["제품", "샤프트 등록"],
      },
    },
    {
      path: "griplist",
      alias: "",
      name: "GripList",
      component: () =>
        import(/* webpackChunkName: "tournament" */ "@/views/goods/GripList"),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "종류별",
        menu_num: 1,
        sub_menu_num: 11,
        step: ["제품", "종류별"],
      },
    },
    {
      path: "gripwrite/:gripno?",
      name: "GripWrite",
      component: () =>
        import(/* webpackChunkName: "tournament" */ "@/views/goods/GripWrite"),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "그립 등록",
        menu_num: 1,
        sub_menu_num: 14,
        step: ["제품", "그립 등록"],
      },
    },
  ],
};

export default routes;
