const routes = {
  path: "/ad",
  name: "Ad",
  component: () =>
    import(/* webpackChunkName: "tournament" */ "@/views/ad/Main"),
  children: [
    {
      path: "adlist/1/:goodstype?",
      alias: "",
      name: "AdList1",
      component: () =>
        import(/* webpackChunkName: "tournament" */ "@/views/ad/AdList"),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "동영상 광고",
        menu_num: 2,
        sub_menu_num: 211,
        step: ["광고", "동영상 광고"],
        adtype: 1,
      },
    },

    {
      path: "adlist/2/:goodstype?",
      alias: "",
      name: "AdList2",
      component: () =>
        import(/* webpackChunkName: "tournament" */ "@/views/ad/AdList"),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "타이틀 광고",
        menu_num: 2,
        sub_menu_num: 221,
        step: ["광고", "타이틀 광고"],
        adtype: 2,
      },
    },

    {
      path: "adlist/3/:goodstype?",
      alias: "",
      name: "AdList3",
      component: () =>
        import(/* webpackChunkName: "tournament" */ "@/views/ad/AdList"),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "제품 광고",
        menu_num: 2,
        sub_menu_num: 231,
        step: ["광고", "제품 광고"],
        adtype: 3,
      },
    },
    {
      path: "adwrite/:adno?",
      name: "AdWrite",
      component: () =>
        import(/* webpackChunkName: "tournament" */ "@/views/ad/AdWrite"),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "광고 등록",
        menu_num: 2,
        sub_menu_num: 24,
        step: ["광고", "광고 등록"],
      },
    },
  ],
};

export default routes;
