const routes = {
  path: "/sale",
  name: "Sale",
  component: () =>
    import(/* webpackChunkName: "tournament" */ "@/views/sale/Main"),
  children: [
    {
      path: "fittingsalelist/:goodstype?",
      alias: "",
      name: "FittingSaleList",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/sale/FittingSaleList"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "판매 목록",
        menu_num: 6,
        sub_menu_num: 61,
        step: ["판매", "판매 목록"],
      },
    },
    {
      path: "fittingsaleview/:fittinganswerno",

      name: "FittingSaleView",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/sale/FittingSaleView"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "판매 내역",
        menu_num: 6,
        sub_menu_num: 62,
        step: ["판매", "판매 내역"],
      },
    },
    // {
    //   path: "fittingshippingview/:ship_pk",
    //   name: "FittingShippingView",
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "tournament" */ "@/views/sale/FittingShippingView"
    //     ),
    //   prop: true,
    //   meta: {
    //     layout: "DefaultLayout",
    //     wrapId: "sub",
    //     subtitle: "판매 배송 보기",
    //     menu_num: 6,
    //     sub_menu_num: 62,
    //     step: ["판매", "판매 배송 보기"],
    //   },
    // },
    // {
    //   path: "fittingshippingwrite",
    //   name: "FittingShippingWrite",
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "tournament" */ "@/views/sale/FittingShippingWrite"
    //     ),
    //   prop: true,
    //   meta: {
    //     layout: "DefaultLayout",
    //     wrapId: "sub",
    //     subtitle: "판매 배송 등록",
    //     menu_num: 6,
    //     sub_menu_num: 63,
    //     step: ["판매", "판매 배송 등록"],
    //   },
    // },
  ],
};

export default routes;
