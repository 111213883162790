const routes = {
  path: "/account",
  name: "Account",
  component: () =>
    import(/* webpackChunkName: "tournament" */ "@/views/account/Main"),
  children: [
    {
      path: "brandpwdcheck",
      name: "BrandPwdCheck",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/account/BrandPwdCheck"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "비밀번호 확인",
        menu_num: 9,
        sub_menu_num: 91,
        step: ["계정관리", "비밀번호 확인"],
      },
    },
    {
      path: "brandwrite",
      name: "BrandWrite",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/account/BrandWrite"
        ),
      props: true,
      password_yn: "",
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "계정정보 수정",
        menu_num: 9,
        sub_menu_num: 92,
        step: ["계정관리", "계정정보 수정"],
      },
    },
  ],
};

export default routes;
