const routes = {
  path: "/customer",
  name: "Customer",
  component: () =>
    import(/* webpackChunkName: "tournament" */ "@/views/customer/Main"),
  children: [
    {
      path: "noticelist",
      alias: "",
      name: "NoticeList",
      category: 0,
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/customer/NoticeList"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "전체",
        menu_num: 8,
        sub_menu_num: 81,
        step: ["고객지원", "전체"],
        category: 0,
      },
    },
    {
      path: "noticelist/1",
      alias: "",
      name: "NoticeList1",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/customer/NoticeList"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "공지사항",
        menu_num: 8,
        sub_menu_num: 82,
        step: ["고객지원", "공지사항"],
        category: 1,
      },
    },
    {
      path: "noticelist/2",
      alias: "",
      name: "NoticeList2",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/customer/NoticeList"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "A/S",
        menu_num: 8,
        sub_menu_num: 83,
        step: ["고객지원", "A/S"],
        category: 2,
      },
    },
    {
      path: "noticelist/3",
      alias: "",
      name: "NoticeList3",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/customer/NoticeList"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "제안사항",
        menu_num: 8,
        sub_menu_num: 84,
        step: ["고객지원", "제안사항"],
        category: 3,
      },
    },
    /*     {
      path: "noticelist/4",
      alias: "",
      name: "NoticeList4",
      component: () =>
        import(
          "@/views/customer/NoticeList"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "기타",
        menu_num: 8,
        sub_menu_num: 81,
        step: ["고객지원", "기타"],
        category: 4,
      },
    }, */
    {
      path: "noticeview/:noticeno",
      name: "NoticeView",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/customer/NoticeView"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "상세보기",
        menu_num: 8,
        sub_menu_num: 0,
        step: ["고객지원", "상세보기"],
      },
    },
    {
      path: "noticewrite/:noticeno?",
      name: "NoticeWrite",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/customer/NoticeWrite"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "등록",
        menu_num: 8,
        sub_menu_num: 0,
        step: ["고객지원", "등록"],
      },
    },
    {
      path: "noticereplywrite/:noticeno",
      name: "NoticeReplyWrite",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/customer/NoticeReplyWrite"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "답변",
        menu_num: 8,
        sub_menu_num: 0,
        step: ["고객지원", "답변"],
      },
    },
  ],
};

export default routes;
