const routes = {
  path: "/brand",
  name: "Brand",
  component: () =>
    import(/* webpackChunkName: "tournament" */ "@/views/brand/Main"),
  children: [
    {
      path: "adminbrandlist",
      alias: "",
      name: "AdminBrandList",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/brand/AdminBrandList"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "브랜드 리스트",
        menu_num: 10,
        sub_menu_num: 101,
        step: ["브랜드", "브랜드 리스트"],
      },
    },
    {
      path: "adminbrandwrite/:brandno?",
      name: "AdminBrandWrite",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/brand/AdminBrandWrite"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "브랜드 등록",
        menu_num: 10,
        sub_menu_num: 101,
        step: ["브랜드", "브랜드 등록"],
      },
    },
  ],
};

export default routes;
