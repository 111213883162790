const routes = {
  path: "/member",
  name: "Member",
  component: () =>
    import(/* webpackChunkName: "tournament" */ "@/views/member/MemberList"),
  children: [
    {
      path: "memberlist",
      alias: "",
      name: "MemberList",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/member/MemberList"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "회원 목록",
        menu_num: 4,
        sub_menu_num: 41,
        step: ["회원", "회원 목록"],
      },
    },
    // {
    //   path: "memberview/:player_pk",
    //   name: "MemberView",
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "tournament" */ "@/views/member/MemberView"
    //     ),
    //   prop: true,
    //   meta: {
    //     layout: "DefaultLayout",
    //     wrapId: "sub",
    //     subtitle: "회원 상세보기",
    //     menu_num: 4,
    //     sub_menu_num: 41,
    //     step: ["제품", "회원 상세보기"],
    //   },
    // },
    // {
    //   path: "memberwrite",
    //   name: "MemberWrite",
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "tournament" */ "@/views/member/MemberWrite"
    //     ),
    //   prop: true,
    //   meta: {
    //     layout: "DefaultLayout",
    //     wrapId: "sub",
    //     subtitle: "회원 등록",
    //     menu_num: 4,
    //     sub_menu_num: 41,
    //     step: ["회원", "회원 등록"],
    //   },
    // },
    // {
    //   path: "membermailwrite",
    //   name: "MemberMailWrite",
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "tournament" */ "@/views/member/MemberMailWrite"
    //     ),
    //   prop: true,
    //   meta: {
    //     layout: "DefaultLayout",
    //     wrapId: "sub",
    //     subtitle: "회원 메일 쓰기",
    //     menu_num: 4,
    //     sub_menu_num: 42,
    //     step: ["회원", "회원 메일 쓰기"],
    //   },
    // },
  ],
};

export default routes;
