import Vue from "vue";

Vue.filter("comma", (value, text) => {
  //if (value) return String(value).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',') // ios 오류
  //if (value) return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',') // 소수점도 , 찍힘
  if (value) {
    const parts = value.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  } else return text || 0;
});

Vue.filter("toFixed", (value, text) => {
  if (value) {
    return Number(value).toFixed(2);
  } else return text || 0;
});

Vue.filter("GMTtuUTCTIME", (value) => {
  if (value) {
    var date = new Date(value);
    var now_utc = Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );

    return new Date(now_utc)
      .toISOString()
      .replace("T", " ")
      .replace(/\..*/, "");
  } else {
    return "";
  }
});

Vue.filter("GMTtuUTC", (value) => {
  if (value) {
    var date = new Date(value);
    var now_utc = Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );

    return new Date(now_utc).toISOString().split("T")[0];
  } else {
    return "";
  }
});

Vue.filter("get_custormer_category", (value) => {
  switch (value) {
    case "1":
      return "공지";

    case "2":
      return "A/S";

    case "3":
      return "제안";

    case "4":
      return "기타";

    default:
      return "전체";
  }
});

Vue.filter("get_custormer_category_bg", (value) => {
  switch (value) {
    case "1":
      return "bg-purple";

    case "2":
      return "bg-orange";

    case "3":
      return "bg-teal";

    case "4":
      return "bg-dark";

    default:
      return "전체";
  }
});

Vue.filter("get_club_type", (value) => {
  switch (value) {
    case "1":
      return "드라이버";

    case "2":
      return "우드";

    case "3":
      return "유틸리티";

    case "4":
      return "아이언";

    case "5":
      return "웨지";

    case "6":
      return "퍼터";

    default:
      return "기타";
  }
});

Vue.filter("get_club_code", (value) => {
  switch (value) {
    case "1":
      return "DR";

    case "2":
      return "W2";

    case "3":
      return "I3";

    case "4":
      return "U4";

    case "5":
      return "PW";

    case "6":
      return "AW";

    default:
      return "ETC";
  }
});

Vue.filter("get_shaft_material", (value) => {
  switch (value) {
    case "1":
      return "스틸";

    case "2":
      return "그라파이트";

    case "3":
      return "카본";
  }
});

Vue.filter("get_state_cd", (value) => {
  switch (value) {
    case "1":
      return "배송대기";

    case "2":
      return "배송중";

    case "3":
      return "배송완료";
  }
});

Vue.filter("get_balance", (value) => {
  switch (value) {
    case "1":
      return "R";

    case "2":
      return "SR";

    case "3":
      return "S";

    case "4":
      return "L";
  }
});

Vue.filter("get_interestclub", (value) => {
  switch (value) {
    case "1":
      return "타구감";

    case "2":
      return "방향감";

    case "3":
      return "비거리";
  }
});

Vue.filter("set_bracket", (value) => {
  if (value) {
  }
  return;
});

Vue.filter("get_img", (value) => {
  if (value) {
    return process.env.VUE_APP_S3_BUCKET_URL + "/" + value;
  } else {
    return "/img/no-img.png";
  }
});

Vue.filter("get_lesson_title", (value) => {
  switch (value) {
    case "lesson1":
      return "Address";

    case "lesson2":
      return "Back Swing";

    case "lesson3":
      return "Top Swing";

    case "lesson4":
      return "Down Swing";

    case "lesson5":
      return "Impact";

    case "lesson6":
      return "Follow Through";

    case "lesson7":
      return "Finish";

    case "lessontotal":
      return "총평";
  }
});

Vue.filter("get_handicap", (value) => {
  switch (value) {
    case "1":
      return "-79";

    case "2":
      return "80-89";

    case "3":
      return "90-99";

    case "4":
      return "+100";

    default:
      return "+100";
  }
});

Vue.filter("get_driverdistance", (value) => {
  switch (value) {
    case "1":
      return "+240";

    case "2":
      return "220-240";

    case "3":
      return "200-220";

    case "4":
      return "180-200";

    case "5":
      return "-179";

    default:
      return "+100";
  }
});

Vue.filter("gender", (value) => {
  if (value === "M") return "남성";
  else return "여성";
});
