const routes = {
  path: "/shop",
  name: "Shop",
  component: () =>
    import(/* webpackChunkName: "tournament" */ "@/views/shop/ShopList"),
  children: [
    {
      path: "shoplist",
      alias: "",
      name: "ShopList",
      component: () =>
        import(/* webpackChunkName: "tournament" */ "@/views/shop/ShopList"),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "매장 목록",
        menu_num: 3,
        sub_menu_num: 31,
        step: ["매장", "매장 목록"],
      },
    },
    // {
    //   path: "shopview/:fit_shop_pk",
    //   name: "ShopView",
    //   component: () =>
    //     import(/* webpackChunkName: "tournament" */ "@/views/shop/ShopView"),
    //   prop: true,
    //   meta: {
    //     layout: "DefaultLayout",
    //     wrapId: "sub",
    //     subtitle: "매장 상세보기",
    //     menu_num: 3,
    //     sub_menu_num: 31,
    //     step: ["매장", "매장 상세보기"],
    //   },
    // },
    // {
    //   path: "shopwrite",
    //   name: "ShopWrite",
    //   component: () =>
    //     import(/* webpackChunkName: "tournament" */ "@/views/shop/ShopWrite"),
    //   prop: true,
    //   meta: {
    //     layout: "DefaultLayout",
    //     wrapId: "sub",
    //     subtitle: "매장 등록",
    //     menu_num: 3,
    //     sub_menu_num: 31,
    //     step: ["매장", "매장 등록"],
    //   },
    // },
    // {
    //   path: "shoproomview",
    //   alias: "",
    //   name: "ShopRoomView",
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "tournament" */ "@/views/shop/ShopRoomView"
    //     ),
    //   prop: true,
    //   meta: {
    //     layout: "DefaultLayout",
    //     wrapId: "sub",
    //     subtitle: "매장 룸 상세보기",
    //     menu_num: 3,
    //     sub_menu_num: 32,
    //     step: ["매장", "매장 룸 상세보기"],
    //   },
    // },
    // {
    //   path: "shopmailwrite",
    //   name: "ShopMailWrite",
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "tournament" */ "@/views/shop/ShopMailWrite"
    //     ),
    //   prop: true,
    //   meta: {
    //     layout: "DefaultLayout",
    //     wrapId: "sub",
    //     subtitle: "매장 메일 쓰기",
    //     menu_num: 3,
    //     sub_menu_num: 33,
    //     step: ["매장", "매장 메일 쓰기"],
    //   },
    // },
  ],
};

export default routes;
