const routes = {
  path: "/fitting",
  name: "Fitting",
  component: () =>
    import(/* webpackChunkName: "tournament" */ "@/views/fitting/Main"),
  children: [
    {
      path: "fittinglist",
      name: "FittingList",
      alias: "",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/fitting/FittingList"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "피팅 목록",
        menu_num: 5,
        sub_menu_num: 51,
        step: ["피팅", "피팅 목록"],
      },
    },
    {
      path: "fittingswinglist/:fittingno",
      name: "FittingSwingList",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/fitting/FittingSwingList"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "피팅 스윙 리스트",
        menu_num: 5,
        sub_menu_num: 52,
        step: ["피팅", "피팅 스윙 리스트"],
      },
    },
    // {
    //   path: "fittingswingview/:fit_pk",
    //   name: "FittingSwingView",
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "tournament" */ "@/views/fitting/FittingSwingView"
    //     ),
    //   prop: true,
    //   meta: {
    //     layout: "DefaultLayout",
    //     wrapId: "sub",
    //     subtitle: "피팅 스윙 상세보기",
    //     menu_num: 5,
    //     sub_menu_num: 53,
    //     step: ["피팅", "피팅 스윙 상세보기"],
    //   },
    // },
    {
      path: "fittingmemberview/:player_pk",
      name: "FittingMemberView",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/fitting/FittingMemberView"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "피팅 회원 상세 보기",
        menu_num: 5,
        sub_menu_num: 54,
        step: ["피팅", "피팅 회원 상세 보기"],
      },
    },
    // {
    //   path: "fittinganswerpreview/:fit_pk",
    //   name: "FittingAnswerPreView",
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "tournament" */ "@/views/fitting/FittingAnswerPreView"
    //     ),
    //   prop: true,
    //   meta: {
    //     layout: "DefaultLayout",
    //     wrapId: "sub",
    //     subtitle: "피팅 답변 미리보기",
    //     menu_num: 5,
    //     sub_menu_num: 55,
    //     step: ["피팅", "피팅 답변 미리보기"],
    //   },
    // },
    {
      path: "fittinganswerview/:fitreqno",
      name: "FittingAnswerView",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/fitting/FittingAnswerView"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "피팅 내역 보기",
        menu_num: 5,
        sub_menu_num: 56,
        step: ["피팅", "피팅 내역 보기"],
      },
    },
    {
      path: "fittinganswerwrite/:fitreqno",
      name: "FittingAnswerWrite",
      component: () =>
        import(
          /* webpackChunkName: "tournament" */ "@/views/fitting/FittingAnswerWrite"
        ),
      prop: true,
      meta: {
        layout: "DefaultLayout",
        wrapId: "sub",
        subtitle: "피팅",
        menu_num: 5,
        sub_menu_num: 56,
        step: ["피팅", "피팅 등록"],
      },
    },
    // {
    //   path: "fittingadlist",
    //   name: "FittingAdList",
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "tournament" */ "@/views/fitting/FittingAdList"
    //     ),
    //   prop: true,
    //   meta: {
    //     layout: "DefaultLayout",
    //     wrapId: "sub",
    //     subtitle: "피팅 답변 광고 리스트",
    //     menu_num: 5,
    //     sub_menu_num: 57,
    //     step: ["피팅", "피팅 답변 광고 리스트"],
    //   },
    // },
    // {
    //   path: "fittinganswerlist",
    //   name: "FittingAnswerList",
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "tournament" */ "@/views/fitting/FittingAnswerList"
    //     ),
    //   prop: true,
    //   meta: {
    //     layout: "DefaultLayout",
    //     wrapId: "sub",
    //     subtitle: "피팅 답변 리스트",
    //     menu_num: 5,
    //     sub_menu_num: 58,
    //     step: ["피팅", "피팅 답변 리스트"],
    //   },
    // },
    // {
    //   path: "fittinganswerresultlist",
    //   name: "FittingAnswerResultList",
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "tournament" */ "@/views/fitting/FittingAnswerResultList"
    //     ),
    //   prop: true,
    //   meta: {
    //     layout: "DefaultLayout",
    //     wrapId: "sub",
    //     subtitle: "피팅 결과 리스트",
    //     menu_num: 5,
    //     sub_menu_num: 59,
    //     step: ["피팅", "피팅 결과 리스트"],
    //   },
    // },
    // {
    //   path: "fittinganswerlessonlist",
    //   name: "FittingAnswerLessonList",
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "tournament" */ "@/views/fitting/FittingAnswerLessonList"
    //     ),
    //   prop: true,
    //   meta: {
    //     layout: "DefaultLayout",
    //     wrapId: "sub",
    //     subtitle: "피팅 레슨 리스트",
    //     menu_num: 5,
    //     sub_menu_num: 59,
    //     step: ["피팅", "피팅 레슨 리스트"],
    //   },
    // },
  ],
};

export default routes;
