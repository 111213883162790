export const myMixin = {
  data() {
    return {
      engMonth: {
        "01": "January",
        "02": "February",
        "03": "March",
        "04": "April",
        "05": "May",
        "06": "June",
        "07": "July",
        "08": "August",
        "09": "September",
        "10": "October",
        "11": "November",
        "12": "December"
      }
    };
  },
  methods: {
    tournament_status(status) {
      if (status === "1") {
        return "대기";
      } else if (status === "2") {
        return "진행";
      } else if (status === "4") {
        return "취소";
      } else if (status === "3") {
        return "만료";
      }
    },
    change_status(status) {
      if (status == 0) {
        return "대기";
      } else if (status == 1) {
        return "진행";
      } else if (status == 2) {
        return "완료";
      } else {
        return "취소";
      }
    },
    event_type(event) {
      if (event == 0) {
        return "전체";
      } else if (event == 1) {
        return "홀인원";
      } else if (event == 2) {
        return "알바트로스";
      } else if (event == 3) {
        return "이글-파5";
      } else if (event == 4) {
        return "버디-파5";
      } else if (event == 5) {
        return "파-파5";
      } else if (event == 6) {
        return "이글-파4";
      } else if (event == 7) {
        return "버디-파4";
      } else if (event == 8) {
        return "파-파4";
      } else if (event == 9) {
        return "롱기스트";
      } else if (event == 10) {
        return "니어핀";
      }
    },
    changeDate(date) {
      let dateArray = date.split("-");
      let engDate =
        this.engMonth[dateArray[1]] + " " + dateArray[2] + ", " + dateArray[0];
      return engDate;
    },
    getDate(date) {
      if (date) {
        var date = new Date(date);
        var now_utc = Date.UTC(
          date.getUTCFullYear(),
          date.getUTCMonth(),
          date.getUTCDate(),
          date.getUTCHours(),
          date.getUTCMinutes(),
          date.getUTCSeconds()
        );

        return new Date(now_utc).toISOString().split("T")[0];
      } else {
        return "";
      }
    },
    changeGender(gender) {
      if (gender === "M") {
        return "남성";
      } else if (gender === "F") {
        return "여성";
      } else {
        return "";
      }
    },
    play_type(type) {
      if (type === "1") {
        return "스트로크";
      } else if (type === "2") {
        return "매치";
      } else if (type === "3") {
        return "스킨스";
      } else if (type === "4") {
        return "어프로치";
      } else if (type === "5") {
        return "드라이브웨이";
      } else if (type === "6") {
        return "파3 플레이";
      } else if (type === "7") {
        return "롱기스트 경연";
      } else if (type === "8") {
        return "퍼티 경연";
      } else if (type === "9") {
        return "니어핀 경연";
      }
    },
    tournament_level(level) {
      if (level === "1") {
        return "프로";
      } else if (level === "2") {
        return "준프로";
      } else if (level === "3") {
        return "고급";
      } else if (level === "4") {
        return "일반";
      } else if (level === "5") {
        return "초보";
      }
    },
    club_type(event) {
      if (event == 0) {
        return "전체";
      } else if (event == 1) {
        return "드라이버";
      } else if (event == 2) {
        return "우드";
      } else if (event == 3) {
        return "유틸리티";
      } else if (event == 4) {
        return "아이언";
      } else if (event == 5) {
        return "웨지";
      } else if (event == 6) {
        return "퍼터";
      // } else if (event == 7) {
      //   return "기타";     
      }
    },
    goods_type(event) {
      if (event == 0) {
        return "전체";
      } else if (event == 1) {
        return "드라이버";
      } else if (event == 2) {
        return "우드";
      } else if (event == 3) {
        return "유틸리티";
      } else if (event == 4) {
        return "아이언";
      } else if (event == 5) {
        return "웨지";
      } else if (event == 6) {
        return "퍼터";
      } else if (event == 7) {
        return "샤프트"; 
      } else if (event == 8) {
        return "그립";       
      }
    },
  }
};
